var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useCallback, useEffect, useRef, useState } from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { useMatchesViewport } from '../hooks/media';
import dispatchTrackingEvent from '../tracking/dispatchTrackingEvent';
import { dispatchTrackingEventGA4 } from '../tracking/dispatchTrackingEventGA4';
import { getSafeEventHandlerOpts } from '../utils/touchEvent';
var MIN_DESKTOP_HEIGHT = 400;
var MIN_MOBILE_HEIGHT = 300;
var TRACKING_EVENT_NAME = 'ScrollToTop_button_click';
var TOUCH_EVENT_OPTIONS = getSafeEventHandlerOpts({
    capture: true,
    passive: false,
});
var bodyOverflowHiddenRequests = 0;
var trackEvent = function () {
    dispatchTrackingEventGA4({
        event: TRACKING_EVENT_NAME,
        element: 'button',
        button_text: 'scroll to top',
    });
    dispatchTrackingEvent({
        eventCategory: TRACKING_EVENT_NAME,
        eventAction: 'button-click',
        eventLabel: 'scroll to top',
    });
};
var resetBodyOverflowHidden = function () {
    if (bodyOverflowHiddenRequests > 0) {
        bodyOverflowHiddenRequests--;
        if (bodyOverflowHiddenRequests === 0) {
            document.body.style.removeProperty('overflow');
        }
    }
};
var applyBodyOverflowHidden = function () {
    document.body.style.overflow = 'hidden';
    bodyOverflowHiddenRequests++;
};
export var useHiddenBodyOverflow = function (shouldLockScroll) {
    var requestedBodyOverflowHidden = useRef(false);
    useEffect(function () {
        if (shouldLockScroll) {
            requestedBodyOverflowHidden.current = true;
            applyBodyOverflowHidden();
        }
        return function () {
            if (requestedBodyOverflowHidden.current) {
                requestedBodyOverflowHidden.current = false;
                resetBodyOverflowHidden();
            }
        };
    }, [shouldLockScroll]);
};
var toggleBodyScroll = function (shouldLockScroll, targetElement) {
    if (shouldLockScroll) {
        disableBodyScroll(targetElement);
    }
    else {
        enableBodyScroll(targetElement);
    }
};
export var useDisableBodyScroll = function (shouldLockScroll, targetElementSelector) {
    return useEffect(function () {
        var targetElement = document.querySelector(targetElementSelector);
        if (targetElement) {
            toggleBodyScroll(shouldLockScroll, targetElement);
        }
    }, [shouldLockScroll, targetElementSelector]);
};
export var useTrackScrollPosition = function (ref) {
    var _a = __read(useState({ x: 0, y: 0 }), 2), scrollPosition = _a[0], setScrollPosition = _a[1];
    var onScroll = useCallback(function (event) {
        var _a = event.target, y = _a.scrollTop, x = _a.scrollLeft;
        setScrollPosition({ x: x, y: y });
    }, []);
    useEffect(function () {
        if (ref) {
            ref.addEventListener('scroll', onScroll, TOUCH_EVENT_OPTIONS);
        }
        return function () {
            if (ref) {
                ref.removeEventListener('scroll', onScroll, TOUCH_EVENT_OPTIONS);
            }
        };
    }, [ref]);
    return scrollPosition;
};
export var useScrollToTop = function () {
    var _a = __read(useState(false), 2), isButtonVisible = _a[0], setIsButtonVisible = _a[1];
    var matchesDesktop = useMatchesViewport().matches.matchesDesktop;
    var minHeight = matchesDesktop ? MIN_DESKTOP_HEIGHT : MIN_MOBILE_HEIGHT;
    useEffect(function () {
        var onScroll = function () {
            setIsButtonVisible(window.scrollY > minHeight);
        };
        window.addEventListener('scroll', onScroll);
        return function () { return window.removeEventListener('scroll', onScroll); };
    }, [minHeight]);
    var scrollToTop = function () {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        trackEvent();
    };
    return { scrollToTop: scrollToTop, isButtonVisible: isButtonVisible };
};
